import React, { useEffect } from "react"
import * as S from "./styles"

export default function Modal(props) {
  const {
    children,
    autoHide = 0,
    $visible = false,
    toggle,
    type = "danger",
    $closable = false,
    $maxWidth = 20,
    padding = 20,
    $topMargin = 0,
    hideBackdrop = false,
  } = props

  useEffect(() => {
    if (autoHide && $visible) {
      setTimeout(() => toggle(), autoHide * 1000)
    }
  })

  return $visible ? (
    <S.Container>
      {!hideBackdrop && <S.Backdrop onClick={toggle} />}
      <S.Modal type={type} $maxWidth={$maxWidth} $topMargin={$topMargin}>
        <S.ModalContent $closable={$closable} $padding={padding}>
          {$closable ? <S.CloseButton onClick={toggle}>✕</S.CloseButton> : null}
          {children}
        </S.ModalContent>
      </S.Modal>
    </S.Container>
  ) : null
}
