import styled from "styled-components"

export const Chip = styled.div<{ color: string }>`
  all: unset;
  height: 22px;
  padding: 6px 14px;
  border-radius: 18px;
  font-size: 15px;
  color: ${(props) => props.theme.colors.black};
  transition: background-color 0.2s;
  background-color: ${(props) => props.theme.colors[props.color]};
  margin: 0 0.5rem 0.5rem 0 ;
  line-height: 22px;
`
