function compare(a, b) {
  if (a.trailDistance < b.trailDistance) {
    return -1
  } else if (a.trailDistance > b.trailDistance) {
    return 1
  } else if (a.trailDistance === b.trailDistance && a.mark) {
    return -1
  } else if (a.trailDistance === b.trailDistance && !a.mark) {
    return 1
  }

  return 0
}

export default function sortTrail(trailPlaces, trailId) {
  // Set current trail distance to short
  for (let i = 0; i < trailPlaces.length; i++) {
    trailPlaces[i].trailDistance = getPlaceDistanceForTrail(trailPlaces[i], trailId)
  }

  return trailPlaces.sort(compare)
}

export function getPlaceDistanceForTrail(place, trailId) {
  if (!Array.isArray(place.trails)) return place.trailsDistances[0] // From marks without trail
  const trailIndex = place.trails.findIndex((trail) => trail === trailId)
  return place.trailsDistances[trailIndex]
}