import React, { useContext, useState, useEffect } from "react"
import {
  auth,
  googleProvider,
  facebookProvider,
  phoneProvider,
} from "../firebase"

const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()
  const [loading, setLoading] = useState(true)

  async function signup(email, password) {
    const credential = await auth.createUserWithEmailAndPassword(
      email,
      password
    )
    await credential.user.sendEmailVerification({ url: "https://postelpropoutnika.cz"})
    return credential
  }

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password)
  }

  function loginGoogle() {
    return auth.signInWithPopup(googleProvider)
  }

  function loginFacebook() {
    return auth.signInWithPopup(facebookProvider)
  }

  function logout() {
    return auth.signOut()
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email)
  }

  function linkPhone(phoneNumber, appVerifier) {
    return currentUser.linkWithPhoneNumber(phoneNumber, appVerifier)
  }

  function verifyPhone(phoneNumber, appVerifier) {
    return phoneProvider.verifyPhoneNumber(phoneNumber, appVerifier)
  }

  function updatePhone(phoneNumber) {
    return currentUser.updatePhoneNumber(phoneNumber)
  }

  async function updatePassword(password) {
    return await currentUser.updatePassword(password)
  }

  function updateDisplayName(name) {
    return currentUser.updateProfile({ displayName: name })
  }

  async function verifyEmail() {
    return await currentUser.sendEmailVerification({
      url: window.location.origin,
    })
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user)
      setLoading(false)
    })

    return unsubscribe
  }, [])

  const value = {
    currentUser,
    login,
    loginGoogle,
    loginFacebook,
    signup,
    logout,
    resetPassword,
    linkPhone,
    verifyPhone,
    updatePhone,
    verifyEmail,
    updatePassword,
    updateDisplayName,
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}
