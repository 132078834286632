import styled from "styled-components"
import MuiPhoneNumberAlias from "material-ui-phone-number"

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000000aa;
  z-index: 1150;
`

export const Modal = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 20rem;
  padding: 2rem;
  background-color: var(--white);
  border-radius: 1rem;

  @media only screen and (max-width: 600px) {
    min-width: 66vw;
  }
`

export const Title = styled.h2`
  margin-top: 0;
`

export const Close = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  padding: 1rem;
  cursor: pointer;
`

export const Error = styled.div`
  color: var(--danger);
`

export const MuiPhoneNumber = styled(MuiPhoneNumberAlias)`
  .MuiPhoneNumber-flagButton {
    height: 30px;
    padding: 0;
    min-width: 30px;

    svg {
      height: 11px;
    }
  }
`
