import React, { useState, useEffect } from "react"
import { useAuth } from "../../contexts/AuthContext"
import { useLocal } from "../../contexts/LocalContext"
import strToUrl from "../../helpers/strToUrl"
import { Link, useNavigate, useParams } from "react-router-dom"
import Modal from "../../primitives/modal"
import ModalPhoneUpdate from "../../primitives/modal-phone-update"
import * as S from "./styles"
import {
  svatojakubske_cesty,
  husova_cesta,
  cyrilometodejske_cesty,
  cesta_ceska
} from "../../svgs"

export default function Dashboard() {
  const { currentUser } = useAuth()
  const { onboardingCompleted, setOnboardingCompleted, trails, webpSupport } =
    useLocal()
  const navigate = useNavigate()
  const [error, setError] = useState("")
  const [showPhoneWizard, setShowPhoneWizard] = useState(false)
  const [trailSet, setTrailSet] = useState("svatojakubske_cesty")
  const { trailSetId } = useParams()

  useEffect(() => {
    console.log(currentUser.phoneNumber, onboardingCompleted());
    window.scrollTo({ top: 0, });
  }, [])

  useEffect(() =>{
    window.history.replaceState(null, "", `/${trailSet}`)
  }, [trailSet])

  useEffect(() => {
    // Set trail set by url
    if (trailSetId !== undefined) {
      setTrailSet(trailSetId)
    }
  }, [trails, trailSetId])

  useEffect(() => {
    window.scrollTo(0, 0)
    if (!currentUser.phoneNumber && !onboardingCompleted()) {
      setOnboardingCompleted()
      setShowPhoneWizard(true)
      console.log('showWizard');
    }
  }, [currentUser.phoneNumber, onboardingCompleted, setOnboardingCompleted])

  return (
    <>
      {showPhoneWizard && (
        <ModalPhoneUpdate
          visible={true}
          close={() => setShowPhoneWizard(false)}
        />
      )}
      <Modal $visible={error !== ""} autoHide={5} toggle={() => setError("")}>
        {error}
      </Modal>
      <S.TrailSetNav>
        <ul>
          <li
            onClick={() => setTrailSet("svatojakubske_cesty")}
            className={trailSet === "svatojakubske_cesty" ? "active" : ""}
          >
            {svatojakubske_cesty}
            <S.TrailSetNavTitle>Svatojakubské cesty</S.TrailSetNavTitle>
          </li>
          <li
            onClick={() => setTrailSet("husova_cesta")}
            className={trailSet === "husova_cesta" ? "active" : ""}
          >
            {husova_cesta}
            <S.TrailSetNavTitle>Husova cesta</S.TrailSetNavTitle>
          </li>
          <li
            onClick={() => setTrailSet("cyrilometodejske_cesty")}
            className={trailSet === "cyrilometodejske_cesty" ? "active" : ""}
          >
            {cyrilometodejske_cesty}
            <S.TrailSetNavTitle>Cyrilometodějské cesty</S.TrailSetNavTitle>
          </li>
          <li
            onClick={() => setTrailSet("cesta_ceska")}
            className={trailSet === "cesta_ceska" ? "active" : ""}
          >
            {cesta_ceska}
            <S.TrailSetNavTitle>Cesta Česka</S.TrailSetNavTitle>
          </li>
        </ul>
      </S.TrailSetNav>
      <S.Main>
        <S.TrailsConntainer>
          {trails
            .filter((trail) => trail.trailSet === trailSet)
            .map((trail, index) => (
              <S.TrailCard
                onClick={() => {
                  navigate("/trail/" + strToUrl(trail.name))
                }}
                key={index}
              >
                <S.TrailCardImage
                  $background={`/images/trails/${strToUrl(trail.name)}_720${webpSupport ? ".webp" : ".jpg"
                    }`}
                  $maskImage={`/images/trails/${strToUrl(trail.name)}.svg`}
                />
                <S.TrailCardTitle>
                  {trail.name}
                  <S.IconArrow src="/images/arrow_right.svg" alt="profile" />
                </S.TrailCardTitle>
                <S.TrailCardLink>
                  <Link to={trail?.link?.url} target="_blank" rel="noopener">
                    {trail?.link?.desc}
                  </Link>
                </S.TrailCardLink>
              </S.TrailCard>
            ))}
        </S.TrailsConntainer>
      </S.Main>
    </>
  )
}
