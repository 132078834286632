const breakPoints = {
  xs: 360,
  sm: 680,
  md: 992,
  lg: 1260,
  xl: 1400,
}

const theme = {
  breakPoints: { ...breakPoints },
  colors: {
    primary: "#CFB023",
    primaryDark: "#B29620",
    gray: "#e9e9e9",
    grayDark: "#A5A6A6",
    grayDarker: "#7C7C7C",
    grayLight: "#f4f4f4",
    black: "#212322",
    white: "#FFFFFF",
    whiteDark: "#eeeeee",
    success: "#00996b",
    info: "#006fea",
    warning: "#d9a900",
    danger: "#e34f00"
  },
  media: {
    tinyOnly: `@media screen and (max-width: ${breakPoints.xs - 0.1}px)`,
    xsUp: `@media screen and (min-width: ${breakPoints.xs}px)`,
    smUp: `@media screen and (min-width: ${breakPoints.sm}px)`,
    smDown: `@media screen and (max-width: ${breakPoints.md - 0.01}px)`,
    mdUp: `@media screen and (min-width: ${breakPoints.md}px)`,
    lgUp: `@media screen and (min-width: ${breakPoints.lg}px)`,
    xl: `@media screen and (min-width: ${breakPoints.xl}px)`,
  },
  sizes: {
    appBarHeight: "56px",
    containerWidth: "1320px",
  },
}

export default theme

export type ThemeType = typeof theme
