import React from "react"
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import App from "./js/App"
import "./styles/index.scss"

Sentry.init({
  dsn: "https://4ba7777107d44c108b94d3eba74486df@o4505091326148608.ingest.sentry.io/4505091329425408",
  tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);