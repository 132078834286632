import styled from "styled-components"
import MuiPhoneNumberAlias from "material-ui-phone-number"

export const Main = styled.main`
  max-width: ${(props) => props.theme.sizes.containerWidth};
  margin: 1rem;

  ${(props) => props.theme.media.smUp} {
    margin: auto;
    padding: 0 2rem;
  }
`

export const Title = styled.h1`
  ${(props) => props.theme.media.smUp} {
    margin-top: 2rem;
  }
`

export const TextFieldWrapper = styled.div`
  margin: 1rem 0;
`

export const FormRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
`

export const Divider = styled.hr`
  border: 0;
  height: 1px;
  background: var(--grayLight);
  margin: 1rem -1rem;

  ${(props) => props.theme.media.smUp} {
    margin: 1rem 0 1rem;
  }
`

export const MuiPhoneNumber = styled(MuiPhoneNumberAlias)`
  .MuiPhoneNumber-flagButton {
    height: 30px;
    padding: 0;
    min-width: 30px;

    svg {
      height: 11px;
    }
  }
`
