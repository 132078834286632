import React from "react"
import * as S from "./styles"
import { MapContainer, TileLayer, Marker, Polyline } from 'react-leaflet'
import "leaflet/dist/leaflet.css"
import L from 'leaflet';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});


export default function FullScreenMap(props) {
  const { place, trailCoords, onClick } = props

  return (
    <S.FullscreenMap>
      <S.FullscreenButtonClose onClick={() => onClick(false)}>
        ✕ Zavřít mapu
      </S.FullscreenButtonClose>
      <S.MapyCzAttr href="http://mapy.cz/" target="_blank">
        <img src="https://api.mapy.cz/img/api/logo.svg" alt="Mapy.cz" />
      </S.MapyCzAttr>
      <MapContainer center={[place.address[2], place.address[3]]} zoom={13} scrollWheelZoom={false}>
        <TileLayer
          attribution='&copy; <a href="https://api.mapy.cz/copyright" target="_blank">&copy; Seznam.cz a.s. a další</a>'
          url="https://api.mapy.cz/v1/maptiles/basic/256/{z}/{x}/{y}?apikey=RD5Am5LzqmSacYx8a9QRgVVvtWNbMLgdRPD1Y6veDTY"
        />
        <Marker position={[place.address[2], place.address[3]]} />
        <Polyline pathOptions={{ color: 'red' }} positions={trailCoords} />
      </MapContainer>
    </S.FullscreenMap>
  )
}
