import styled from "styled-components"

export const Button = styled.button<{
  disabled: boolean
  color: string
  size: string
  $blank: boolean
}>`
  all: unset;
  ${(props) => !props.disabled && `cursor: pointer;`}
  height: 22px;
  padding: 8px 16px;
  margin-right: 0.5rem;
  font-size: 16px;
  border: ${(props) =>
    props.$blank
      ? `1px solid #ffffffff`
      : props.color === "white"
      ? `1px solid ${props.theme.colors.black}`
      : props.color === "gray" || props.disabled
      ? `1px solid ${props.theme.colors.gray}`
      : `1px solid ${props.theme.colors.primary}`};
  border-radius: 20px;
  color: ${(props) => props.theme.colors.black};
  transition: background-color 0.2s, border 0.2s;
  background-color: ${(props) =>
    props.disabled ? props.theme.colors.gray : props.theme.colors[props.color]};
  ${(props) =>
    props.size === "large" &&
    `
  font-family: "Suisseintl";
  font-weight: 700;
  font-size: 26px;
  height: 40px;
  border-radius: 30px;
  padding: 10px 20px;
  `}
  ${(props) =>
    props.size === "small" &&
    `
  font-family: "Suisseintl";
  font-size: 14px;
  height: 18px;
  border-radius: 16px;
  padding: 6px 14px;
  `}

  ${(props) =>
    !props.disabled &&
    `&:hover {
    background-color: ${props.theme.colors[props.color + "Dark"]};
    border: 1px solid ${props.theme.colors[props.color + "Dark"]};
  }`}
`
