import XMLParser from "react-xml-parser"

export default async function getTrailMap(name) {
  const jihoceskaTrasaURL = `/trails/${name}.gpx`
  let coordsFormated = []

  try {
    const response = await fetch(jihoceskaTrasaURL)
    const trailDataXML = await response.text()
    const trailDataJSON = new XMLParser().parseFromString(trailDataXML)
    const coords = trailDataJSON.children[0].children[1].children
  
    coords.map((coord) =>
      coordsFormated.push({
        lat: coord.attributes.lat,
        lng: coord.attributes.lon,
      })
    )
  } catch (error) {
    console.log(error)
    throw error
  }

  return coordsFormated
}
