import React, { useContext, useState, useEffect } from "react"
import { useDb } from "./DbContext"
import hasWebpSupport from "../helpers/hasWebpSupport"

const LocalContext = React.createContext()

export function useLocal() {
  return useContext(LocalContext)
}

export function LocalProvider({ children }) {
  const { getTrails } = useDb()
  const [trails, setTrails] = useState([])
  const [webpSupport, setWebPSupport] = useState(false)

  useEffect(() => {
    ;(async () => {
      try {
        const trails = await getTrails()
        const trailsArray = []

        trails.forEach((trail) => trailsArray.push(trail.data()))

        trailsArray.sort((a,b) => a.order - b.order)

        setTrails(trailsArray)
      } catch (err) {
        console.log("Error getting trails:" + err)
      }
    })()

    return () => {
      setTrails([])
    }
  }, [getTrails])

  useEffect(() => {
    if (hasWebpSupport) setWebPSupport(true)
  }, [setWebPSupport])

  function setOnboardingCompleted() {
    localStorage.setItem("onboardingCompleted", true)
  }

  const onboardingCompleted = () => {
    return localStorage.getItem("onboardingCompleted")
  }

  const value = {
    setOnboardingCompleted,

    onboardingCompleted,
    trails,
    webpSupport,
  }

  return <LocalContext.Provider value={value}>{children}</LocalContext.Provider>
}
