import styled from "styled-components"

export const Main = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 75vh;
`

export const Container = styled.div`
  display: flex;

  ${(props) => props.theme.media.smDown} {
    flex-direction: column;
  }
`

export const Partner = styled.div`
  margin: 3rem;
  display: flex;
  justify-content: center;
  
  > a {
    > img {
      max-width: 100%;
      height: auto;
    }
  }
`