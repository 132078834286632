import React from "react"
import * as S from "./styles"

interface Props {
  children?: string
  content?: string
  color?: string
  size?: string
  disabled?: boolean
  blank?: boolean
  onClick: () => void
}

export default function Button(props: Props) {
  const {
    content,
    children = "",
    color = "primary",
    size = "default",
    blank = false,
    disabled = false,
    onClick,
  } = props

  return (
    <S.Button color={color} onClick={onClick} size={size} disabled={disabled} $blank={blank}>
      {content ?? children}
    </S.Button>
  )
}
