export default function passwordStrenght(password) {
  let level = { strength: 0, value: "slabé" }

  switch (true) {
    case /^(?=.*\p{Ll})(?=.*\p{Lu})(?=.*[\d|@#$!%*?&])[\p{L}\d@#$!%*?&]{8,}$/gmu.test(password):
      level.strength = 2
      level.value = "výborné"
      break
    case /^(?=.*\p{Ll})(?=.*\p{Lu})[\p{L}\d@#$!%*?&]{8,}$/gmu.test(password):
      level.strength = 1
      level.value = "dobré"
      break
    default:
      level.strength = 0
      level.value = "slabé"
  }

  return level
}
