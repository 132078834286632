import React from "react"
import * as S from "./styles"

export default function PilgrimRules() {
  return (
    <>
      <h2>Desatero poutníka</h2>
      <ol>
        <S.ListItem>
          Délka ubytování je 1 noc, ve výjimečných odůvodněných případech a po
          souhlasu ubytovatele je to možné déle.
        </S.ListItem>
        <S.ListItem>
          Chovej se k ubytovatelům tak, jak chceš, aby se oni chovali k Tobě.
        </S.ListItem>
        <S.ListItem>
          Pokud spíš u ubytovatele s pevnou cenou, respektuj ji a nesmlouvej o
          jinou. Pokud bydlíš u ubytovatele za dobrovolný příspěvek, zohledni
          náklady, které s Tvým pobytem ubytovatel má. Našim cílem je
          dlouhodobost a udržitelnost a to může fungovat jen v případě
          rovnocenného vztahu mezi poutníky a ubytovateli. Pokud nemůžeš za svůj
          pobyt zaplatit vůbec nic, informuj o tom ubytovatele předem.
          Respektuj, pokud Tě v takovém případě odmítne.
        </S.ListItem>
        <S.ListItem>
          Opusť místo svého ubytování v o trošku lepším stavu, než v jakém jsi
          ho našel/našla.
        </S.ListItem>
        <S.ListItem>
          Respektuj hraniční časy příchodu a odchodu. Každý ubytovatel je má
          jinak, buď k tomu pozorný/pozorná.
        </S.ListItem>
        <S.ListItem>
          Kontaktuj ubytovatele s poptávkou mista alespoň 1-2 dny před svým
          plánovaným příchodem.
        </S.ListItem>
        <S.ListItem>
          Věř svým instinktům. Pokud Ti v seznamu ubytovatelů někdo nesedí,
          nechoď k němu spát.
        </S.ListItem>
        <S.ListItem>
          Pokud potřebuješ víc informací než je uvedeno na profilu ubytovatele,
          zeptej se. Nepředpokládej, jak něco je nebo není.
        </S.ListItem>
        <S.ListItem>
          Pokud se z nějakého důvodu rozhodneš ubytování nevyužít, informuj o
          tom ubytovatele v nejkratším možném termínu. Tvé místo může posloužit
          někomu jinému.
        </S.ListItem>
        <S.ListItem>
          Celý koncept je pro nás zatím velmi nový a hledáme jeho optimální
          podobu. Budeme rádi za Tvou zpětnou vazbu a tipy ke zlepšení.Pokud
          zažiješ během ubytování nepříjemnou nebo nebezpečnou situaci, okamžitě
          to nahlas. Kontaktní e-mail je{" "}
          <a href="mailto:postel@ultreia.cz">postel@ultreia.cz</a>.
        </S.ListItem>
      </ol>
    </>
  )
}
