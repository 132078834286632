import React from "react"
import * as S from "./styles"

interface Props {
  children?: string
  content?: string
  color?: string
}

export default function Chip(props: Props) {
  const {
    content,
    children = "",
    color = "primary",
  } = props

  return (
    <S.Chip color={color}>
      {content ?? children}
    </S.Chip>
  )
}
