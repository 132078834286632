import styled, { keyframes } from "styled-components"

export const Main = styled.main`
  max-width: ${(props) => props.theme.sizes.containerWidth};
  margin: 1rem;

  ${(props) => props.theme.media.smUp} {
    margin: auto;
    padding: 0 2rem;
  }
`

export const Title = styled.h1`
  ${(props) => props.theme.media.smUp} {
    margin: 2rem 0;
  }
`

export const TextFieldWrapper = styled.div`
  margin: 1rem 0;
`

export const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const ImageWrapper = styled.div`
  width: 5rem;
  height: 5rem;
  border-radius: 1rem;
  margin: 0.5rem;
  &:hover {
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' baseProfile='basic' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 80 80' xml:space='preserve'%3E%3Cpath fill='%23BBBBBB' d='M57.5,27.2c-0.2-0.2-0.5-0.3-0.8-0.3h-7.5l-1.7-4c-0.3-0.7-0.8-1.2-1.5-1.7c-0.7-0.5-1.4-0.7-2.1-0.7h-8 c-0.7,0-1.4,0.2-2.1,0.7c-0.7,0.5-1.2,1-1.5,1.7l-1.7,4h-7.5c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8v1.6 c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3h2.1v23.4c0,1.4,0.4,2.7,1.2,3.7c0.8,1,1.9,1.6,3.1,1.6h20.7c1.2,0,2.2-0.5,3.1-1.6 c0.8-1.1,1.2-2.3,1.2-3.8V30.7h2.1c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8V28C57.9,27.7,57.8,27.5,57.5,27.2z M34.9,26.9 l1-2.4c0.1-0.1,0.1-0.1,0.1-0.1l7.8,0c0.1,0,0.2,0.1,0.2,0.1l1,2.5H34.9z M50.4,55.5c0,0.1-0.1,0.1-0.1,0.1H29.7 c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.2-0.2-0.3-0.6c-0.1-0.3-0.2-0.6-0.2-0.9V30.7h21.7v23.3c0,0.3-0.1,0.6-0.2,0.9 C50.6,55.2,50.5,55.4,50.4,55.5z'/%3E%3Cpath fill='%23BBBBBB' d='M34.4,34.9h-1.6c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8v14.4c0,0.3,0.1,0.6,0.3,0.8 c0.2,0.2,0.5,0.3,0.8,0.3h1.6c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8V36c0-0.3-0.1-0.6-0.3-0.8C35,35,34.7,34.9,34.4,34.9z' /%3E%3Cpath fill='%23BBBBBB' d='M40.8,34.9h-1.6c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8v14.4c0,0.3,0.1,0.6,0.3,0.8 c0.1,0.1,0.4,0.3,0.8,0.3h1.6c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8V36c0-0.3-0.1-0.6-0.3-0.8C41.4,35,41.1,34.9,40.8,34.9 z'/%3E%3Cpath fill='%23BBBBBB' d='M47.2,34.9h-1.6c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8v14.4c0,0.3,0.1,0.6,0.3,0.8 c0.2,0.2,0.5,0.3,0.8,0.3h1.6c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8V36c0-0.3-0.1-0.6-0.3-0.8C47.8,35,47.5,34.9,47.2,34.9 z'/%3E%3C/svg%3E%0A");
  }
`

export const Image = styled.img`
  width: 5rem;
  height: 5rem;
  border-radius: 1rem;
  cursor: pointer;
  object-fit: cover;
  &:hover {
    opacity: 0.2;
  }
`
export const ImagePlaceholder = styled.div`
  width: 5rem;
  height: 5rem;
  margin: 0.5rem;
  border-radius: 1rem;
  background-color: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  font-weight: 900;
  color: #bbb;
  cursor: pointer;

  &:hover {
    background-color: #ddd;
  }
`

export const HiddenInput = styled.input`
  opacity: 0;
  z-index: -1;
  position: absolute;
`

const rotate = keyframes`
  100% { 
    transform: rotate(360deg); 
  } 
`

export const Spin = styled.div`
  animation: ${rotate} 2s linear infinite;
`

export const Right = styled.div`
  margin-bottom: 1.5rem;
  float: right;
`

export const Margin = styled.div`
  margin-bottom: 1.5rem;
`
