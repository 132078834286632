import React, { useState } from "react"
import { useAuth } from "../../contexts/AuthContext"
import { useDb } from "../../contexts/DbContext"
import { useLocal } from "../../contexts/LocalContext"
import { useNavigate } from "react-router-dom"
import {
  Button,
  TextField,
  FormHelperText,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core"
import passwordStrenght from "../../helpers/passwordStrenght"
import Modal from "../../primitives/modal"
import strToUrl from "../../helpers/strToUrl"
import * as S from "./styles"

export default function Signup() {
  const { signup } = useAuth()
  const { createUser } = useDb()
  const { trails, webpSupport } = useLocal()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState({
    email: "",
    password: "",
    passwordConfirm: "",
  })
  const [pPCheck, setPPCheck] = useState(false)
  const navigate = useNavigate()

  async function handleSubmit(e) {
    e.preventDefault()

    if (state.password !== state.passwordConfirm) {
      return setError("Hesla se neshodují")
    }

    try {
      setError("")
      setLoading(true)
      const cred = await signup(state.email, state.password)
      await createUser(cred.user.uid, state.email)
      navigate("/")
    } catch (error) {
      if (error.code === "auth/email-already-in-use") {
        setError(
          "Ups. Tahle adresa už je registrovaná. Zkus jinou nebo si nech poslat nové heslo."
        )
      } else if (error.code === "auth/invalid-email") {
        setError("Špatný formát emailu.")
      } else if (error.code === "auth/weak-password") {
        setError("Heslo musí mít aspoň 6 znaků.")
      } else {
        setError("Nepodařilo se vytvořit účet:" + error.message)
      }
      console.log(error)
    }

    setLoading(false)
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  return (
    <>
      <Modal $visible={true} type="primary" $maxWidth={100}>
        <form onSubmit={handleSubmit}>
          <S.ModalTitle>Vytvoření účtu</S.ModalTitle>
          <S.TextFieldWrapper>
            <TextField
              label="E-mail"
              required
              variant="outlined"
              style={{ width: 300 }}
              onChange={handleChange}
              name="email"
            />
          </S.TextFieldWrapper>
          <S.TextFieldWrapper>
            <TextField
              label="Nové heslo"
              required
              variant="outlined"
              onChange={handleChange}
              name="password"
              type="password"
              style={{ width: 300 }}
            />
            {state.password !== "" ? (
              state.password.length < 6 ? (
                <FormHelperText id="details-helper-text">
                  Heslo musí mít alespoň 6 znaků
                </FormHelperText>
              ) : (
                <FormHelperText id="details-helper-text">
                  Heslo je {passwordStrenght(state.password).value}
                </FormHelperText>
              )
            ) : null}
          </S.TextFieldWrapper>
          <S.TextFieldWrapper>
            <TextField
              label="Nové heslo znovu"
              required
              variant="outlined"
              onChange={handleChange}
              name="passwordConfirm"
              type="password"
              style={{ width: 300 }}
            />
          </S.TextFieldWrapper>
          <FormControlLabel
            control={
              <Checkbox
                name="gdpr_agreement"
                onChange={() => setPPCheck(!pPCheck)}
                color="white"
              />
            }
            label={
              <S.P>
                Souhlasím se&nbsp;
                <a href="/content/privacy_policy.html" target="_blank">
                  zpracováním
                  <br /> osobních údajů
                </a>
              </S.P>
            }
          />
          <S.ButtonWrapper>
            <Button
              disabled={loading || !pPCheck}
              type="submit"
              variant="contained"
              color="primary"
              className="main-button"
              style={{ width: 300 }}
            >
              Vytvořit účet
            </Button>
          </S.ButtonWrapper>
          <S.P>
            <S.Link to="/forgot-password">Zapomněl/a jsi heslo?</S.Link>
          </S.P>
          <S.P>
            Máš už účet? <S.Link to="/login">Přihlas se</S.Link>
          </S.P>
        </form>
      </Modal>
      <main>
        <S.TrailsConntainer>
          {trails.map((trail, index) => (
            <S.TrailCard key={index}>
              <S.TrailCardImage
                $background={`/images/trails/${strToUrl(trail.name)}_720${webpSupport ? ".webp" : ".jpg"
                  }`}
                $maskImage={`/images/trails/${strToUrl(trail.name)}.svg`}
              />
              <S.TrailCardTitle>
                {trail.name}
                <S.IconArrow src="/images/arrow_right.svg" alt="profile" />
              </S.TrailCardTitle>
              <S.TrailCardLink>popis trasy na ultreia.cz</S.TrailCardLink>
            </S.TrailCard>
          ))}
        </S.TrailsConntainer>
      </main>
      {error !== "" && (
        <Modal
          $visible={error !== ""}
          autoHide={3}
          hideBackdrop={true}
          $topMargin={5}
          toggle={() => setError("")}
        >
          {error}
        </Modal>
      )}
    </>
  )
}
