import styled from "styled-components"

export const FullscreenMap = styled.div`
  position: absolute;
  top: 54px;
  left: 0;
  width: 100vw;
  height: calc(100vh - 54px);
  background: var(--gray);
  z-index: 99;

  > div {
     width: 100%;
     height:100%;
   }
`

export const FullscreenButtonClose = styled.button`
  all: unset;
  position: absolute;
  z-index: 999;
  bottom: 20px;
  left: 20px;
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  border: 2px solid var(--grayLight);
  cursor: pointer;
  
  &:hover {
    background: #f4f4f4;
  }

  &:focus {
    outline: revert;
  }
`

export const MapyCzAttr = styled.a`
  position: absolute;
  z-index: 999;
  bottom: 20px;
  right: 20px;
`