export const bungalow = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27.733"
    height="32"
    viewBox="0 0 27.733 32"
  >
    <path
      id="bungalow_FILL0_wght400_GRAD0_opsz48_2_"
      data-name="bungalow_FILL0_wght400_GRAD0_opsz48 (2)"
      d="M13.378,38V25.289L10.667,29.6,8.4,28.222,22.267,6,36.133,28.222,33.867,29.6l-2.711-4.311V38Zm2.667-2.667h4.889V30H23.6v5.333h4.889V21.022l-6.222-9.956-6.222,9.956Zm4.889-10.222V22.444H23.6v2.667ZM16.044,35.333h0Z"
      transform="translate(-8.4 -6)"
      fill="#212322"
    />
  </svg>
)

export const family_restroom = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="33.09"
    height="32"
    viewBox="0 0 33.09 32"
  >
    <path
      id="family_restroom_FILL0_wght400_GRAD0_opsz48"
      d="M29.826,10.242a2.835,2.835,0,0,1-2.078-.868A2.835,2.835,0,0,1,26.88,7.3a2.835,2.835,0,0,1,.868-2.078,2.923,2.923,0,0,1,4.156,0A2.835,2.835,0,0,1,32.772,7.3,2.835,2.835,0,0,1,31.9,9.374,2.835,2.835,0,0,1,29.826,10.242ZM27.4,36.35V22.791a4.027,4.027,0,0,0-.605-2.179A3.47,3.47,0,0,0,25.024,19.2l1.654-4.842a3.051,3.051,0,0,1,1.19-1.614,3.467,3.467,0,0,1,3.914,0,3.051,3.051,0,0,1,1.19,1.614l4.116,11.9H32.651V36.35ZM20.747,19.4a2.425,2.425,0,0,1-1.715-4.136,2.425,2.425,0,0,1,3.43,3.43A2.335,2.335,0,0,1,20.747,19.4Zm-11.1-9.16a2.835,2.835,0,0,1-2.078-.868A2.835,2.835,0,0,1,6.7,7.3a2.835,2.835,0,0,1,.868-2.078,2.923,2.923,0,0,1,4.156,0A2.835,2.835,0,0,1,12.6,7.3a2.835,2.835,0,0,1-.868,2.078A2.835,2.835,0,0,1,9.649,10.242ZM6.825,36.35v-11.7H4V14.559a2.411,2.411,0,0,1,2.421-2.421h6.456A2.411,2.411,0,0,1,15.3,14.559V24.648H12.474v11.7Zm11.7,0V29.49H16.509v-6.7a1.791,1.791,0,0,1,1.776-1.776h4.923a1.791,1.791,0,0,1,1.776,1.776v6.7H22.966v6.86Z"
      transform="translate(-4 -4.35)"
      fill="#212322"
    />
  </svg>
)

export const fork_right = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24.889"
    height="32"
    viewBox="0 0 24.889 32"
  >
    <path
      id="fork_right_FILL0_wght400_GRAD0_opsz48_2_"
      data-name="fork_right_FILL0_wght400_GRAD0_opsz48 (2)"
      d="M17.867,38V11.067l-4,4L12,13.2,19.2,6l7.2,7.2-1.867,1.867-4-4V25.289a10.9,10.9,0,0,1,3.178-2.4,10.133,10.133,0,0,1,4.6-.933A12.75,12.75,0,0,1,30,22.089a15.282,15.282,0,0,1,1.822.356l-4-4,1.867-1.867,7.2,7.2-7.2,7.2-1.867-1.867,4-4a10.461,10.461,0,0,0-1.689-.356,14.787,14.787,0,0,0-2-.133,7.8,7.8,0,0,0-4.644,1.511,7.916,7.916,0,0,0-2.956,4.311V38Z"
      transform="translate(-12 -6)"
      fill="#212322"
    />
  </svg>
)

export const help = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <path
      id="help_FILL0_wght400_GRAD0_opsz48"
      d="M20.16,29.32a1.5,1.5,0,1,0-1.08-.44A1.468,1.468,0,0,0,20.16,29.32Zm-1.4-5.84h2.36a6.547,6.547,0,0,1,.26-1.9A4.71,4.71,0,0,1,23,19.6a7,7,0,0,0,1.76-2.04,4.7,4.7,0,0,0,.52-2.2,4.416,4.416,0,0,0-1.38-3.4,5.168,5.168,0,0,0-3.66-1.28,6.183,6.183,0,0,0-3.46.98,5.6,5.6,0,0,0-2.18,2.7l2.12.8a3.732,3.732,0,0,1,1.32-1.74,3.518,3.518,0,0,1,2.08-.62,3.206,3.206,0,0,1,2.2.74,2.417,2.417,0,0,1,.84,1.9,2.941,2.941,0,0,1-.52,1.66,7.968,7.968,0,0,1-1.52,1.62,8.136,8.136,0,0,0-1.78,2.06,5.445,5.445,0,0,0-.58,2.7ZM20,36a15.481,15.481,0,0,1-6.2-1.26A16.12,16.12,0,0,1,5.26,26.2a15.982,15.982,0,0,1,0-12.44A15.99,15.99,0,0,1,8.7,8.68a16.4,16.4,0,0,1,5.1-3.42,15.982,15.982,0,0,1,12.44,0,15.975,15.975,0,0,1,8.5,8.5,15.982,15.982,0,0,1,0,12.44,16.4,16.4,0,0,1-3.42,5.1,15.99,15.99,0,0,1-5.08,3.44A15.58,15.58,0,0,1,20,36Zm0-2.4a13.087,13.087,0,0,0,9.64-3.98A13.139,13.139,0,0,0,33.6,20a13.12,13.12,0,0,0-3.96-9.64A13.12,13.12,0,0,0,20,6.4a13.139,13.139,0,0,0-9.62,3.96A13.087,13.087,0,0,0,6.4,20a13.106,13.106,0,0,0,3.98,9.62A13.106,13.106,0,0,0,20,33.6ZM20,20Z"
      transform="translate(-4 -4)"
      fill="#212322"
    />
  </svg>
)

export const hiking = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.935"
    height="32"
    viewBox="0 0 20.935 32"
  >
    <path
      id="hiking_FILL0_wght700_GRAD0_opsz48_1_"
      data-name="hiking_FILL0_wght700_GRAD0_opsz48 (1)"
      d="M11.705,33.25l4.351-22.211a2.3,2.3,0,0,1,.93-1.526,2.685,2.685,0,0,1,1.6-.544,3.3,3.3,0,0,1,1.526.368,3.035,3.035,0,0,1,1.175,1.07l1.333,2.246a6.45,6.45,0,0,0,1.632,1.86,7.6,7.6,0,0,0,2.228,1.158v-2.7h2.175V33.25H26.477V18.689a10.693,10.693,0,0,1-2.895-1.175,12.315,12.315,0,0,1-2.649-2.088l-.807,4.105L23.039,22.3V33.25H20.161V24.513l-2.877-2.737L14.758,33.25ZM11.811,19.6,9,19.039a1.458,1.458,0,0,1-.982-.7,1.627,1.627,0,0,1-.281-1.193l1.053-5.509a2.974,2.974,0,0,1,1.316-1.947,2.94,2.94,0,0,1,2.3-.474l1.4.316ZM20.9,7.215a2.787,2.787,0,0,1-2.088-.895,2.919,2.919,0,0,1-.86-2.088,2.9,2.9,0,0,1,.86-2.105,2.923,2.923,0,0,1,4.175,0,2.9,2.9,0,0,1,.86,2.105,2.919,2.919,0,0,1-.86,2.088A2.787,2.787,0,0,1,20.9,7.215Z"
      transform="translate(-7.718 -1.25)"
      fill="#212322"
    />
  </svg>
)

export const info = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <path
      id="info_FILL0_wght400_GRAD0_opsz48"
      d="M18.92,28h2.4V18.4h-2.4ZM20,15.44a1.314,1.314,0,0,0,.94-.36,1.212,1.212,0,0,0,.38-.92,1.345,1.345,0,0,0-.38-.96,1.3,1.3,0,0,0-1.88,0,1.345,1.345,0,0,0-.38.96,1.212,1.212,0,0,0,.38.92,1.314,1.314,0,0,0,.94.36ZM20,36a15.481,15.481,0,0,1-6.2-1.26A16.12,16.12,0,0,1,5.26,26.2a15.982,15.982,0,0,1,0-12.44A15.99,15.99,0,0,1,8.7,8.68a16.4,16.4,0,0,1,5.1-3.42,15.982,15.982,0,0,1,12.44,0,15.975,15.975,0,0,1,8.5,8.5,15.982,15.982,0,0,1,0,12.44,16.4,16.4,0,0,1-3.42,5.1,15.99,15.99,0,0,1-5.08,3.44A15.58,15.58,0,0,1,20,36Zm.04-2.4a13.04,13.04,0,0,0,9.6-3.98,13.186,13.186,0,0,0,3.96-9.66,13.073,13.073,0,0,0-3.96-9.6A13.12,13.12,0,0,0,20,6.4a13.139,13.139,0,0,0-9.62,3.96A13.087,13.087,0,0,0,6.4,20a13.106,13.106,0,0,0,3.98,9.62A13.153,13.153,0,0,0,20.04,33.6ZM20,20Z"
      transform="translate(-4 -4)"
      fill="#212322"
    />
  </svg>
)

export const kitchen = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25.6"
    height="32"
    viewBox="0 0 25.6 32"
  >
    <path
      id="kitchen_FILL0_wght400_GRAD0_opsz48"
      d="M13.96,13.2V8.48h2.4V13.2Zm0,14.44V19.8h2.4v7.84ZM10.4,36A2.39,2.39,0,0,1,8,33.6V6.4A2.39,2.39,0,0,1,10.4,4H31.2a2.39,2.39,0,0,1,2.4,2.4V33.6A2.39,2.39,0,0,1,31.2,36Zm0-2.4H31.2V17.68H10.4Zm0-18.32H31.2V6.4H10.4Z"
      transform="translate(-8 -4)"
      fill="#212322"
    />
  </svg>
)

export const notifications = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25.6"
    height="32"
    viewBox="0 0 25.6 32"
  >
    <path
      id="notifications_FILL0_wght400_GRAD0_opsz48"
      d="M8,31.2V28.8h3.36V16.56a9.657,9.657,0,0,1,1.98-5.98,8.533,8.533,0,0,1,5.22-3.3V6.12a1.965,1.965,0,0,1,.66-1.52,2.38,2.38,0,0,1,3.16,0,1.965,1.965,0,0,1,.66,1.52V7.28a8.616,8.616,0,0,1,5.24,3.3,9.593,9.593,0,0,1,2,5.98V28.8H33.6v2.4ZM20.8,19.4Zm0,16.6a3.1,3.1,0,0,1-2.24-.94,3.05,3.05,0,0,1-.96-2.26H24A3.209,3.209,0,0,1,20.8,36Zm-7.04-7.2H27.88V16.56a6.932,6.932,0,0,0-2.04-5.04,6.733,6.733,0,0,0-5-2.08,6.8,6.8,0,0,0-5.02,2.08,6.9,6.9,0,0,0-2.06,5.04Z"
      transform="translate(-8 -4)"
      fill="#212322"
    />
  </svg>
)

export const arrow_right = (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 15.1 12.7"
    enableBackground="new 0 0 15.1 12.7"
  >
    <polygon points="8.9,0 7.8,1.1 12.1,5.4 0,5.4 0,7 12.1,7 7.5,11.6 8.6,12.7 15.1,6.2 " />
  </svg>
)

export const arrow_left = (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 15.1 12.7"
    enableBackground="new 0 0 15.1 12.7"
  >
    <polygon points="6.2,12.7 7.4,11.6 3.1,7.3 15.1,7.3 15.1,5.7 3.1,5.7 7.6,1.1 6.5,0 0,6.5 " />
  </svg>
)

export const svatojakubske_cesty = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <title>Logo Svatojakubské cesty</title>
    <path
      fill="#CFB023"
      d="M5.9,5C5.6,5.3,5.3,5.5,5.1,5.6C4.6,5.9,4.4,5.5,4.7,5.1C4.9,4.9,5.4,4.4,6,3.9c1-0.7,1.5-1,2-1.1
		C8.5,2.6,8.8,3,8.2,3.4C7.7,3.8,7.4,4,7,4.3C6.6,4.5,6.2,4.8,5.9,5z M21.8,10.1c-0.2-1.1-0.5-2-1.1-2.5c-0.3-0.3-1.1-0.8-1.8-1.4
		c-1.1-0.9-3.1-2.4-3.9-3C14.2,2.6,13.3,2,11.9,2c-1.4,0-2.3,0.8-3.4,1.7c-1,0.8-1.9,1.4-3,2.2C4.7,6.5,3.6,7.3,3.3,7.5
		C2.8,8,2.6,8.4,2.4,9.2c-0.6,2,0.2,1.6,0.4,1.4c0.3-0.3,0.9-1,2.2-2.3C6.3,6.9,8.1,5,8.3,4.7c0.2-0.3,0.4-0.1,0.2,0.2
		C8.3,5.2,6.7,7.1,5.8,8.1C5,9.1,3,11.3,2.6,11.8c-0.5,0.6-0.6,1.5-0.3,2.7c0.3,1.3,0.8,0.8,1.1,0.4c0.3-0.5,1.3-2.1,2.1-3.4
		C6.8,9.4,9,5.7,9.2,5.4s0.4-0.2,0.2,0.2c-0.2,0.3-3.1,5.7-4.1,7.6c-0.9,1.7-1.4,2.5-1.7,3.2c-0.3,0.5-0.1,1.1,0.6,1.9
		c1,1.2,1.5,0.5,1.6,0.2c0.2-0.4,0.4-1.2,1.4-4c1.1-3.2,2.9-8.2,3-8.5c0.1-0.3,0.3-0.3,0.3,0.1c-0.1,0.4-1.3,4.3-2.3,7.8
		c-1,3.6-1.3,4.6-1.6,5.5C6.3,20,6.4,20.4,7.6,21s1.6,0.3,1.7-0.4c0.1-0.4,0.3-2.2,0.9-6.9s1-7.4,1-7.7c0-0.3,0.3-0.2,0.3,0.1
		c0,0.3-0.2,2.7-0.5,6.6c-0.3,3.9-0.5,6.3-0.6,7.3c-0.1,1.7-0.1,2,1.6,2c1.5,0,1.5-0.3,1.4-1.5c0-0.4-0.2-2.8-0.4-5.8
		c-0.4-5.1-0.6-8.2-0.7-8.5c0-0.4,0.2-0.4,0.3,0c0,0.4,0.6,4.8,1,7.9c0.4,3.1,0.7,5.2,0.7,5.6c0.2,1.6,0.8,1.9,2,1.4
		s1.2-1.1,0.8-2.6c-0.5-1.5-1-3.5-1.7-5.8c-1.2-4-1.9-6.4-2-6.7s0.2-0.4,0.3-0.1c0.1,0.3,2.6,7.5,3.6,10.3c1.1,2.9,1.6,3.3,2.6,2.2
		c1-1.2,0.5-1.8-0.3-3.2c-0.7-1.3-1.7-3-2.4-4.4c-1.2-2.2-2.7-5-2.8-5.2c-0.1-0.2,0.1-0.4,0.2-0.1c0.2,0.3,3.1,5.1,4,6.6
		c0.8,1.2,1.6,2.5,1.9,3.1c0.3,0.5,0.9,0.3,1.1-0.8c0.2-0.9,0.2-1.7-0.3-2.3c-0.4-0.4-2.5-2.8-3.2-3.8c-0.8-0.9-2.7-3.1-2.8-3.4
		c-0.2-0.2,0.1-0.4,0.3-0.1c0.1,0.1,1.9,2,2.8,2.9c0.8,0.9,1.8,1.9,2.7,2.8C21.5,10.9,21.9,10.8,21.8,10.1z M17.5,4.7
		c0.6,0.4,1.2,0.9,1.5,1c0.3,0.2,0.6-0.1,0.4-0.4c-0.2-0.3-0.8-0.9-1.5-1.5c-0.7-0.6-1.2-0.8-1.7-0.9c-0.5-0.1-0.7,0.3-0.4,0.5
		C16.2,3.8,16.8,4.2,17.5,4.7z"
    />
  </svg>
)

export const husova_cesta = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <title>Logo Husova cesta</title>
    <path
      fill="#8D1A0E"
      d="M10.8,3.8c0-0.6-0.1-1.2-0.4-1.8c0.9,0,2.6,0,3.5,0
		c-0.3,0.6-0.4,1.2-0.4,1.8L10.8,3.8z M5.5,2C6.4,2,8.1,2,9,2C8.6,3,8.6,4,8.6,5.1c0,0.5,0,1,0,1.5l0,0.8h2.3l0-2.9l2.7,0l0,2.9
		l2.3,0l0-0.8c0-0.5,0-1,0-1.5c0-1,0-2.1-0.4-3.1c0.9,0,2.6,0,3.5,0c-0.4,1-0.4,2-0.4,3.1c0,0.5,0,1,0,1.5c0,0.5,0,1,0,1.5
		c0,0.5,0,1,0,1.5c0,0.5,0,1,0,1.5c0,1,0,2.1,0.4,3.1c-0.9,0-2.6,0-3.5,0c0.4-1,0.4-2,0.4-3.1c0-0.5,0-1,0-1.5c0-0.5,0-1,0-1.5
		l0-0.4l-1.1,0.1v0l-1.1,0.1l0,6.7c0,0.5,0,2.3,0,2.8c0,4-3.9,5.6-6.8,4.1C6.1,21.1,5,20,5.1,19.2c0-0.1,0.3-0.4,0.3-0.5
		c0,0.1,0.1,0.1,0.1,0.2c-0.1,0.3,0,0.6,0.2,0.9c0.4,0.6,1.2,1.1,2,1.2c1.6,0.2,3.2-1,3.2-3.7c0-0.5,0-2.3,0-2.8l0-6.7L8.6,7.8
		l0,0.4c0,0.5,0,1,0,1.5c0,0.5,0,1,0,1.5c0,1,0,2.1,0.4,3.1c-0.9,0-2.6,0-3.5,0c0.4-1,0.4-2,0.4-3.1c0-0.5,0-1,0-1.5
		c0-0.5,0-1,0-1.5c0-0.5,0-1,0-1.5c0-0.5,0-1,0-1.5C5.9,4,5.9,3,5.5,2z"
    />
  </svg>
)

export const cyrilometodejske_cesty = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <title>Logo Cyrilometodějské cesty</title>
    <path
      fill="#D1AF1D"
      d="M15.4,11.6c-0.3,0-0.5-0.2-0.5-0.5c0-0.2,0.1-0.3,0.2-0.4l0,0c0.8-0.7,1.4-2,1.4-3.2C16.5,5,14.5,3,12,3
			C9.5,3,7.5,5,7.5,7.4c0,1.2,0.6,2.5,1.4,3.2l0,0c0.1,0.1,0.2,0.2,0.2,0.4c0,0.3-0.2,0.5-0.5,0.5c-0.6,0-1.4-1.4-1.6-1.8
			C6.7,9,6.5,8.2,6.5,7.4C6.5,4.5,9,2,12,2c3,0,5.5,2.5,5.5,5.5c0,1.5-0.6,2.8-1.6,3.9l0,0C15.7,11.5,15.6,11.6,15.4,11.6"
    />
    <path
      fill="#D1AF1D"
      d="M17,22c-0.3,0-0.5-0.2-0.5-0.5v-5.2c0-1.1-0.1-1.7-0.5-2.2c-0.4-0.6-1.1-0.9-1.9-0.9
			c-0.6,0-1.1,0.2-1.5,0.6c0,0,0,0,0,0c0.4,0.6,1,1.3,1,2.4v5.3c0,0.3-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5v-5c0-1-0.3-1.5-0.7-2
			c-0.3,0.4-0.7,1-0.7,1.8v5.2c0,0.3-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5v-5.2c0-1.2,0.5-1.9,1-2.5c0,0,0,0,0,0
			c-0.4-0.4-0.9-0.6-1.5-0.6c-0.8,0-1.5,0.4-1.9,1c-0.4,0.5-0.5,1.1-0.5,2.1v5.2C7.5,21.8,7.3,22,7,22c-0.3,0-0.5-0.2-0.5-0.5v-5.1
			c0-1.3,0.1-1.9,0.5-2.6c0.6-1,1.7-1.6,2.8-1.6c0.8,0,1.6,0.3,2.1,0.8c0.6-0.6,1.3-0.8,2.1-0.8c1.2,0,2.3,0.6,2.8,1.6
			c0.4,0.6,0.5,1.3,0.5,2.6v5.1C17.5,21.8,17.3,22,17,22"
    />
  </svg>
)

export const cesta_ceska = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fill="#ED1C24"
      d="M20.8,4.2c-1.5-0.9-3.2-1.3-5-1.3c-5.6,0-9.3,3.9-9.3,9.1c0,5.5,4.1,9.1,9.1,9.1c1.7,0,4-0.4,5.3-1.1
			l0.2,3.2C19.3,23.9,17,24,15.6,24C8,24,2.9,19.3,2.9,11.9C2.9,4.8,8.3,0,15.6,0c1.9,0,3.7,0.3,5.4,1L20.8,4.2z"
    />

    <path
      fill="#ED1C24"
      d="M15.8,7.3c-3.9-3.4-6.6,1.8-4.4,4.2c2.2,2.4,4.4,3.7,4.4,3.7s2.3-1.2,4.4-3.7C22.4,9.1,19.7,3.8,15.8,7.3z"
    />
  </svg>
)
