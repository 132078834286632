import React, { useState, useEffect } from "react"
import { useDb } from "../../contexts/DbContext"
import { useLocal } from "../../contexts/LocalContext"
import { Link, useNavigate , useLocation } from "react-router-dom"
import Modal from "../../primitives/modal"
import strToUrl from "../../helpers/strToUrl"
import numberToLocalString from "../../helpers/numberToLocalString"
import sortTrail from "../../helpers/sortTrail"
import * as S from "./styles"

export default function Trail() {
  const navigate = useNavigate()
  const location = useLocation()
  const { getTrail, getPlace } = useDb()
  const { webpSupport } = useLocal()
  const trailId = location.pathname.split("/").pop() ?? ""
  const [error, setError] = useState("")
  const [trail, setTrail] = useState<Trail | null>(null)
  const [trailPlaces, setTrailPlaces] = useState<Place[]>([])

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [])

  useEffect(() => {
    // Get all places for given trail
    ;(async () => {
      let trailPlacesTemp: Place[] = []

      const querySnapshot = await getTrail(trailId)
      const pids = querySnapshot.data().places

      if (typeof pids !== "undefined") {
        await Promise.all(
          pids.map(async (pid: string) => {
            const place = await getPlace(pid)

            if (place.exists) {
              let placeData = place.data()
              placeData.id = pid
              if (!placeData.hidden) {
                trailPlacesTemp.push(placeData)
              }
            }
          })
        )
        setTrailPlaces(sortTrail(trailPlacesTemp, trailId))
      }
    })()

    // Get Trail details
    getTrail(trailId)
      .then((querySnapshot: { exists: any; data: () => React.SetStateAction<Trail | null> }) => {
        if (querySnapshot.exists) {
          setTrail(querySnapshot.data())
        } else {
          setError("Trasa neexistuje")
        }
      })
      .catch((error: any) => {
        setError(`Nepodařilo se získat dokument: ${error}`)
      })
    return () => {
      setTrailPlaces([])
      setTrail(null)
    }
  }, [getPlace, getTrail, trailId])

  function getDistanceForTrail(place: Place, trailId: string) {
    const trailIndex = place.trails?.findIndex((trail) => trail === trailId) ?? 0
    return place?.distances?.[trailIndex] ?? place.distance ?? -1 // place.distance for backward compatibility
  }

  return (
    <>
      <Modal $visible={error !== ""} autoHide={5} toggle={() => setError("")}>
        {error}
      </Modal>
      <S.Main>
        <S.Banner
          image={
            `/images/trails/${strToUrl(trail?.name ?? "")}${
              webpSupport ? ".webp" : ".jpg"
            }`
          }
        >
          <S.TrailMap
            image={`/images/trails/${strToUrl(trail?.name ?? "")}.svg`}
          />
        </S.Banner>
        <S.Container>
          <S.Title>{trail?.name}</S.Title>
          <p>
            <Link to={trail?.link?.url ?? ''} target="_blank" rel="noopener">
              {trail?.link?.desc}
            </Link>
          </p>
          <hr />
          <S.List>
            {trailPlaces &&
              trailPlaces.map((place, index) =>
                place.mark ? (
                  <S.ListItemMark
                    key={index}
                    $borderBottom={
                      trailPlaces[index + 1] &&
                      trailPlaces[index + 1].mark === true
                    }
                  >
                    <S.MarkTitle
                      $bold={
                        trailPlaces[index + 1] &&
                        trailPlaces[index + 1].mark !== true
                      }
                    >
                      <strong className="kms">
                        {numberToLocalString(place.trailsDistances ? place.trailsDistances[0] : -1)} km
                      </strong>
                      <div className="bullet">•</div>
                      <div className="name">{place.name}</div>
                    </S.MarkTitle>
                  </S.ListItemMark>
                ) : (
                  <S.ListItem
                    key={index}
                    onClick={() =>
                      navigate(
                        "/place/" + place.id + "/" + strToUrl(trail?.name)
                      )
                    }
                    $borderBottom={
                      trailPlaces[index + 1] &&
                      trailPlaces[index + 1].mark === true
                    }
                  >
                    <S.ListItemContainer>
                      <S.PlaceImageContainer>
                        {place?.images ? (
                          <S.PlaceImage
                            src={"https://" + place.images[0]}
                            alt={place.name}
                          />
                        ) : (
                          <S.PlaceImage src="/images/placeholder.png" />
                        )}
                      </S.PlaceImageContainer>
                      <S.PlaceDetails>
                        <S.PlaceName>{place.name}</S.PlaceName>
                        <div>
                          <S.Gray>kapacita:{"\u00A0"}</S.Gray> {place.capacity}{" "}
                          {(place.capacity === 1 && "osoba") ||
                            (place.capacity && place.capacity <= 4 && "osoby") ||
                            "osob"}
                        </div>
                        <div>
                          <S.Gray>cena:{"\u00A0"}</S.Gray>
                          {place.price === -1
                            ? "Dobrovolný příspěvek"
                            : place.price + " Kč"}
                        </div>
                        {place.distance ? (
                          <div>
                            <S.Gray>vzdálenost od trasy:{"\u00A0"}</S.Gray>
                            {getDistanceForTrail(place, trailId) > 5 ? (
                              <S.Red>
                                {numberToLocalString(
                                  getDistanceForTrail(place, trailId)
                                )}{" "}
                                km
                              </S.Red>
                            ) : (
                              <>
                                {numberToLocalString(
                                  getDistanceForTrail(place, trailId)
                                )}{" "}
                                km
                              </>
                            )}
                          </div>
                        ) : null}
                      </S.PlaceDetails>
                    </S.ListItemContainer>
                  </S.ListItem>
                )
              )}
          </S.List>
        </S.Container>
      </S.Main>
    </>
  )
}
