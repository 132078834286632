import styled from "styled-components"
import { Link as LinkAlias } from "react-router-dom"

export const TrailCard = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;

  ${(props) => props.theme.media.mdUp} {
    width: 440px;
  }
`

export const TrailsConntainer = styled.div`
  filter: blur(10px);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: ${(props) => props.theme.sizes.containerWidth};
  margin: 2rem auto;

  > div:nth-last-child(n + 2) {
    ${(props) => props.theme.media.smDown} {
      width: 100%;
      border-bottom: 2px solid ${(props) => props.theme.colors.gray};
    }
  }
`

export const TrailCardImage = styled.div<{
  $background: string
  clipName: string
}>`
  width: 22.5rem;
  height: 14rem;
  margin: 2rem auto;
  background: url(${(props) => props.$background}) no-repeat center center /
    contain;
  clip-path: url(#${(props) => props.clipName});

  &:hover {
    clip-path: none;
    border-radius: 0.75rem;
  }
`

export const TrailCardTitle = styled.div`
  margin: 0 2rem;
  flex-grow: 1;
  font-family: "Suisseintl";
  font-weight: 700;
  font-size: 2.5rem;
`

export const TrailCardLink = styled.p`
  margin: 1rem 2rem 2rem 2rem;
`

export const IconArrow = styled.img`
  width: 27px;
  height: 22px;
  margin-left: 10px;
`

export const ButtonWrapper = styled.div`
  margin-bottom: 0.75rem;

  .MuiButtonBase-root {
    border-radius: 0.5rem;
    line-height: 2.75;
    font-weight: 700;
    text-transform: none;
    font-size: 1rem;
  }

  .main-button {
    background: #ffffff;

    &:hover {
      background: #eeeeee;
    }
  }
`

export const Link = styled(LinkAlias)`
  color: #ffffff;
`

export const ModalTitle = styled.h2`
  font-family: "Suisseintl";
  font-weight: 700;
  font-size: 2.1rem;
  margin-bottom: 1.5rem;
  margin-top: 0.5rem;
`

export const TextFieldWrapper = styled.div`
  margin: 1rem 0;

  .MuiFormLabel-root {
    background: white;
    border-radius: 10px;
    padding: 5px 7px;
    margin: -5px -7px;
  }

  .MuiInputBase-input {
    background: #ffffff;
    border-radius: 0.5rem;

    &:autofill {
      border-radius: 0.5rem;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border: 0;
  }
`

export const P = styled.p`
  color: ${(props) => props.theme.colors.black};

  a {
    color: ${(props) => props.theme.colors.black};
  }
`
