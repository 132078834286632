import React from "react"
import * as S from "./styles"

export default function HostRules() {
  return (
    <>
      <h2>Pár slov pro ubytovatele</h2>
      <p>
        Milá ubytovatelko, milý ubytovateli,
        <br />
        nesmírně si vážíme Tvé ochoty pustit k sobě domů poutníka a poskytnout
        mu přístřeší. Níže uvádíme pár tipů, které mohou usnadnit Váš vzájemný
        kontakt.
      </p>
      <ol>
        <S.ListItem>
          Věnuj dostatek času vyplnění svého profilu ubytovatele. Čím přesnější
          informace uvedeš, tím lepší představu si poutník udělá. Nic nezamlčuj
          ani nepřikrášluj.
        </S.ListItem>
        <S.ListItem>
          Pokud není aktuálně k dispozici něco z vybavení, které ve svém profilu
          uvádíš, informuj o tom poutníka již při rezervaci.
        </S.ListItem>
        <S.ListItem>
          Při ubytování si s poutníkem jasně vymez prostor, který může využívat
          a který už je Tvůj soukromý.
        </S.ListItem>
        <S.ListItem>
          Každý někdy nemáme svůj den. Nebuď překvapený/překvapená, že se jednou
          potkáš s otevřeným veselým poutníkem a jindy s někým, kdo s Tebou
          nebude chtít prohodit víc než pár slov.
        </S.ListItem>
        <S.ListItem>
          Pokud zažiješ během ubytování nepříjemnou nebo nebezpečnou situaci,
          okamžitě to nahlas na postel@ultreia.cz.
        </S.ListItem>
        <S.ListItem>
          Celý koncept je pro nás zatím velmi nový a hledáme jeho optimální
          podobu. Budeme rádi za Tvou zpětnou vazbu a tipy ke zlepšení. Napiš
          nám na <a href="mailto:postel@ultreia.cz">postel@ultreia.cz</a>.
        </S.ListItem>
      </ol>
    </>
  )
}
