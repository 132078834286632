import React, { useState, useRef, useEffect } from "react"
import Modal from "../../primitives/modal"
import { useAuth } from "../../contexts/AuthContext"
import { useNavigate  } from "react-router-dom"
import HostRules from "../../content/HostRules"
import PilgrimRules from "../../content/PilgrimRules"
import * as S from "./styles"

export default function Navigation() {
  const menuRef = useRef<HTMLInputElement>(null)
  const closeButtonRef = useRef<HTMLInputElement>(null)
  const { currentUser, logout } = useAuth()
  const navigate  = useNavigate ()

  const [error, setError] = useState("")
  const [showMenu, setShowMenu] = useState(false)
  const [showHostRules, setShowHostRules] = useState(false)
  const [showPilgrimRules, setShowPilgrimRules] = useState(false)

  document.body.style.overflowX = "hidden"

  useEffect(() => {
    const closeMenu = (e: MouseEvent) => {
      if (
        showMenu &&
        menuRef.current &&
        closeButtonRef.current &&
        !menuRef.current.contains(e.target as Node) &&
        !closeButtonRef.current.contains(e.target as Node)
      ) {
        setShowMenu(false)
        document.removeEventListener("mousedown", closeMenu)
      }
    }

    document.addEventListener("mousedown", closeMenu)
    return () => {
      document.removeEventListener("mousedown", closeMenu)
    }
  }, [showMenu])

  const handleHostProfileClick = () => {
    setShowMenu(false)
    if (currentUser.phoneNumber) {
      navigate("/host-profile")
    } else {
      setError("Pro nabízení ubytování je třeba ověřit telefonní číslo.")
    }
  }

  async function handleLogout() {
    setShowMenu(false)
    setError("")

    try {
      await logout()
      navigate("/login")
    } catch {
      setError("Nepodařilo se odhlásit")
    }
  }

  return (
    <>
      <Modal
        $visible={error !== ""}
        autoHide={5}
        toggle={() => setError("")}
        tyle="danger"
      >
        {error}
      </Modal>
      <Modal
        $visible={showHostRules}
        $closable={true}
        toggle={() => setShowHostRules(false)}
        type="white"
        $maxWidth={0}
      >
        <HostRules />
      </Modal>
      <Modal
        $visible={showPilgrimRules}
        $closable={true}
        toggle={() => setShowPilgrimRules(false)}
        type="white"
        $maxWidth={0}
      >
        <PilgrimRules />
      </Modal>
      <S.Nav $blur={!currentUser}>
        <S.LogoContainer onClick={() => navigate("/")}>
          <S.Logo src="/images/logo_ultreia.svg" alt="Logo Ultreia" />
          <S.LogoTitle>postel pro poutníka</S.LogoTitle>
        </S.LogoContainer>
        <S.MenuButton
          onClick={() => setShowMenu(!showMenu)}
          ref={closeButtonRef}
        >
          {showMenu ? (
            <S.IconMenu src="/images/close.svg" alt="close" />
          ) : (
            <S.IconMenu src="/images/hamburger.svg" alt="menu" />
          )}
        </S.MenuButton>
        <S.Menu $visible={showMenu} ref={menuRef}>
          <S.MenuItem
            onClick={() => {
              setShowMenu(false)
              navigate("/user-profile")
            }}
          >
            <S.MenuItemTitle>profil</S.MenuItemTitle>
            <S.IconArrow src="/images/arrow_right.svg" alt="profile" />
          </S.MenuItem>
          <S.MenuItem onClick={handleHostProfileClick}>
            <S.MenuItemTitle>
              ubytovat
              <br /> poutníky
            </S.MenuItemTitle>
            <S.IconArrow src="/images/arrow_right.svg" alt="My accomodation" />
          </S.MenuItem>
          <S.MenuItem onClick={handleLogout}>
            <S.MenuItemTitle>odhlásit</S.MenuItemTitle>
            <S.IconArrow src="/images/arrow_right.svg" alt="Logout" />
          </S.MenuItem>
        </S.Menu>
      </S.Nav>
    </>
  )
}
