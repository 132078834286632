import React, { useEffect } from "react"
import * as S from "./styles"

export default function Partners() {

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [])

  return (
    <>
      <S.Main>
        <S.Container>
          <S.Partner><a href="https://www.cestaceska.cz/"><img src="/images/logo-cesta_ceska.png" width="173" height="100" alt="Cesta Česka" /></a></S.Partner>
          <S.Partner><a href="https://www.cyril-methodius.cz/cs/"><img src="/images/logo-cyrilometodejska_cesta.png" width="414" height="100" alt="Cyrilometodějská cesta" /></a></S.Partner>
          <S.Partner><a href="https://husovacesta.cz/"><img src="/images/logo-husova_cesta.png" width="200" height="100" alt="Husova cesta" /></a></S.Partner>
        </S.Container>
      </S.Main>
    </>
  )
}
