import React, { useState, useEffect } from "react"
import HostRules from "../../content/HostRules"
import PilgrimRules from "../../content/PilgrimRules"
import Modal from "../../primitives/modal"
import * as S from "./styles"
import { useLocal } from "../../contexts/LocalContext"
import { useAuth } from "../../contexts/AuthContext"
import strToUrl from "../../helpers/strToUrl"
import { Link } from "react-router-dom"

export default function Footer() {
  const { trails } = useLocal()
  const { currentUser } = useAuth()
  const [showHostRules, setShowHostRules] = useState(false)
  const [showPilgrimRules, setShowPilgrimRules] = useState(false)

  useEffect(() => { }, [trails])

  return (
    <>
      <Modal
        $visible={showHostRules}
        $closable={true}
        toggle={() => setShowHostRules(false)}
        type="white"
        $maxWidth={0}
      >
        <HostRules />
      </Modal>
      <Modal
        $visible={showPilgrimRules}
        $closable={true}
        toggle={() => setShowPilgrimRules(false)}
        type="white"
        $maxWidth={0}
      >
        <PilgrimRules />
      </Modal>
      <S.Footer $blur={!currentUser}>
        <S.Container>
          <S.Section>
            <S.Title>svatojakubské trasy</S.Title>
            <S.List>
              {Array.isArray(trails) &&
                trails.filter((trail) => trail.trailSet === 'svatojakubske_cesty')
                  .map((trail, index) => (
                    <li key={index}>
                      <Link to={strToUrl("/trail/" + trail.name)}>
                        {trail.name}
                      </Link>
                    </li>
                  ))}
            </S.List>
          </S.Section>
          <S.Section>
            <S.Title>partnerské trasy</S.Title>
            <S.List>
              <li><Link to="/cesta_ceska">Cesta Česka ↗</Link></li>
              <li><S.List>
                {Array.isArray(trails) &&
                  trails.filter((trail) => trail.trailSet === 'cesta_ceska')
                    .map((trail, index) => (
                      <li key={index}>
                        <Link to={strToUrl("/trail/" + trail.name)}>
                          {trail.name}
                        </Link>
                      </li>
                    ))}
              </S.List>
              </li>
              <li><Link to="/husova_cesta">Husova cesta ↗</Link></li>
              <li><Link to="/cyrilometodejske_cesty">Cyrilometodějská cesta ↗</Link></li>
              <li><S.List>
                {Array.isArray(trails) &&
                  trails.filter((trail) => trail.trailSet === 'cyrilometodejske_cesty')
                    .map((trail, index) => (
                      <li key={index}>
                        <Link to={strToUrl("/trail/" + trail.name)}>
                          {trail.name}
                        </Link>
                      </li>
                    ))}

              </S.List>
              </li>
            </S.List>
          </S.Section>
          <S.Section>
            <S.Title>podpoř naši činnost</S.Title>
            <S.List>
              <li>
                <a
                  href="https://www.darujme.cz/projekt/1202279"
                  target="_blank"
                  rel="noreferrer"
                >
                  darujme.cz
                </a>
              </li>
            </S.List>
          </S.Section>
          <S.Section>
            <S.Title>pomoc</S.Title>
            <S.List>
              <li>
                <S.Link
                  href="#"
                  onClick={(e) => {
                    e.preventDefault()
                    setShowPilgrimRules(true)
                  }}
                >
                  pro poutníka
                </S.Link>
              </li>
              <li>
                <S.Link
                  href="#"
                  onClick={(e) => {
                    e.preventDefault()
                    setShowHostRules(true)
                  }}
                >
                  pro ubytovatele
                </S.Link>
              </li>
              <li>
                <a
                  href="https://forms.gle/TigKNxwu9ga3M9XY9"
                  target="_blank"
                  rel="noreferrer"
                >
                  kontaktní formulář
                </a>
              </li>
            </S.List>
          </S.Section>
          <S.Section>
            <S.Title>profil</S.Title>
            <S.List>
              <li>
                <Link to="/user-profile">nastavení</Link>
              </li>
              <li>
                <Link to="/host-profile">ubytovat poutníky</Link>
              </li>
            </S.List>
          </S.Section>
          <S.Section>
            <S.Title>partneři</S.Title>
            <S.List>
              <li>
                <Link to="/partners">Husova cesta</Link>
              </li>
              <li>
                <Link to="/partners">Cyrilometodějské cesty</Link>
              </li>
              <li>
                <Link to="/partners">Cesta Česka</Link>
              </li>
            </S.List>
          </S.Section>
        </S.Container>
        <a href="//ultreia.cz" target="_blank" rel="noreferrer">
          <S.Logo>
            <img src="/images/logo_ultreia.svg" alt="Ultreia" />
          </S.Logo>
        </a>
      </S.Footer>
    </>
  )
}
