import React, { useState, useEffect } from "react"
import { useAuth } from "../../contexts/AuthContext"
import { useLocal } from "../../contexts/LocalContext"
import { useDb } from "../../contexts/DbContext"
import { useNavigate } from "react-router-dom"
import { Button, TextField } from "@material-ui/core"
import Modal from "../../primitives/modal"
import strToUrl from "../../helpers/strToUrl"
import * as S from "./styles"

export default function Login() {
  const { login, loginGoogle, loginFacebook } = useAuth()
  const { createUser } = useDb()
  const { trails, webpSupport } = useLocal()
  const navigate = useNavigate()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState({ email: "", password: "" })

  useEffect(() => { }, [trails])

  async function handleSubmit(event) {
    event.preventDefault()

    try {
      setError("")
      setLoading(true)
      await login(user.email, user.password)
      navigate("/")
    } catch (error) {
      if (error.code === "auth/user-not-found") {
        setError("Uživatel s tímto e-mailem neexistuje")
      } else if (error.code === "auth/wrong-password") {
        setError("Špatné heslo")
      } else {
        setError("Nepodařilo se přihlásit")
      }
      console.log(error)
    }

    setLoading(false)
  }

  async function handleLoginGoogle(event) {
    event.preventDefault()

    try {
      setError("")
      setLoading(true)
      const result = await loginGoogle()
      await createUser(
        result.user.uid,
        result.user.email,
        true,
        result.user.displayName,
        result.user.phoneNumber,
        result.user.photoURL
      )
    } catch (error) {
      if (error.code === "auth/account-exists-with-different-credential") {
        setError(
          `Přihlas se prosím pomocí e-mailu a hesla. Neumíme Tě přihlásit přes Google, protože ses registroval/a přes e-mail (${error.email}), nebo Facebook.`
        )
      } else {
        setError("Nepodařilo se přihlásit pomocí Google")
      }
      console.log(error)
    } finally {
      setLoading(false)
      navigate("/")
    }
  }

  async function handleLoginFacebook(event) {
    event.preventDefault()

    try {
      setError("")
      setLoading(true)
      const result = await loginFacebook()
      await createUser(
        result.user.uid,
        result.user.email,
        true,
        result.user.displayName,
        result.user.phoneNumber,
        result.user.photoURL
      )
      navigate("/")
    } catch (error) {
      if (error.code === "auth/account-exists-with-different-credential") {
        setError(
          `Přihlas se prosím pomocí e-mailu a hesla. Neumíme Tě přihlásit přes Facebook, protože ses registroval/a přes e-mail (${error.email}), nebo Google.`
        )
      } else {
        setError("Nepodařilo se přihlásit pomocí Facebooku")
      }
      console.log(error)
    }

    setLoading(false)
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  return (
    <>
      <Modal $visible={true} type="primary" $maxWidth={100}>
        <S.LogoContainer onClick={() => navigate("/")}>
          <S.Logo src="/images/logo_ultreia-white.svg" alt="Logo Ultreia" />
          <S.LogoTitle>postel pro poutníka</S.LogoTitle>
        </S.LogoContainer>
        <S.Divider />
        <form onSubmit={handleSubmit}>
          <S.ModalTitle>Postůj, poutníče</S.ModalTitle>
          <S.P>
            Přihlas se nebo se zaregistruj
            <br /> a najdi nocleh na pouť
          </S.P>
          <S.P>
            <a
              href="https://ultreia.cz/postel-pro-poutnika-2/"
              target="_blank"
              rel="noreferrer"
            >
              Co je Postel pro poutníka?
              <br />
              <br />
            </a>
          </S.P>
          <S.TextFieldWrapper>
            <TextField
              label="E-mail"
              required
              variant="outlined"
              style={{ width: 300 }}
              onChange={handleChange}
              name="email"
            />
          </S.TextFieldWrapper>
          <S.TextFieldWrapper>
            <TextField
              label="Heslo"
              required
              variant="outlined"
              onChange={handleChange}
              name="password"
              type="password"
              style={{ width: 300 }}
              autoComplete="current-password"
            />
          </S.TextFieldWrapper>
          <S.ButtonWrapper>
            <Button
              disabled={loading}
              type="submit"
              variant="contained"
              color="primary"
              className="main-button"
              style={{ width: 300 }}
            >
              Přihlásit se
            </Button>
          </S.ButtonWrapper>
          <S.ButtonWrapper>
            <Button
              disabled={loading}
              onClick={handleLoginGoogle}
              variant="contained"
              className="google"
              style={{ width: 300 }}
            >
              Přihlásit se pomocí Google
            </Button>
          </S.ButtonWrapper>
          <S.ButtonWrapper>
            <Button
              disabled={loading}
              onClick={handleLoginFacebook}
              variant="contained"
              className="facebook"
              style={{ width: 300 }}
            >
              Přihlásit se pomocí Facebooku
            </Button>
          </S.ButtonWrapper>
          <S.P>
            <S.Link to="/forgot-password">Zapomněl/a jsi heslo?</S.Link>
          </S.P>
          <S.P>
            Ještě nemáš účet? <S.Link to="/signup">Zaregistruj se</S.Link>
          </S.P>
        </form>
      </Modal>
      <main>
        <S.TrailsConntainer>
          {trails
            .filter(trail => trail.trailSet === 'svatojakubske_cesty')
            .map((trail, index) => (
              <S.TrailCard key={index}>
                <S.TrailCardImage
                  $background={`/images/trails/${strToUrl(trail.name)}_720${webpSupport ? ".webp" : ".jpg"
                    }`}
                  $maskImage={`/images/trails/${strToUrl(trail.name)}.svg`}
                />
                <S.TrailCardTitle>
                  {trail.name}
                  <S.IconArrow src="/images/arrow_right.svg" alt="profile" />
                </S.TrailCardTitle>
                <S.TrailCardLink>popis trasy na ultreia.cz</S.TrailCardLink>
              </S.TrailCard>
            ))}
        </S.TrailsConntainer>
      </main>
      {error !== "" && (
        <Modal
          $visible={error !== ""}
          autoHide={8}
          hideBackdrop={true}
          $topMargin={5}
          toggle={() => setError("")}
        >
          {error}
        </Modal>
      )}
    </>
  )
}
